// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Earn-quests"

import { Value } from './earnQuests.types';

// prettier-ignore
const earnQuests: Value[] = [
  {
    "actionCTA": "join_word",
    "analytics": "join_announcement",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "announcement_channel",
    "migrationKey": "joinAnnouncement",
    "order": 1,
    "reward": 10000,
    "url": "https://t.me/gemz_announcements"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "youtube",
    "id": "follow_youtube",
    "migrationKey": "followOnYoutube",
    "order": 2,
    "reward": 25000,
    "url": "https://www.youtube.com/@gemzfun"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "earn_x",
    "id": "follow_x",
    "migrationKey": "followOnX",
    "order": 3,
    "reward": 10000,
    "url": "https://x.com/gemzfun"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "earn_x",
    "id": "follow_x_jw",
    "migrationKey": "followOnXJW",
    "order": 4,
    "reward": 10000,
    "url": "https://x.com/jtwald"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "ig",
    "id": "join_instagram",
    "migrationKey": "joinInstagram",
    "order": 5,
    "reward": 10000,
    "url": "https://www.instagram.com/gemzfun/"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "tiktok",
    "id": "join_tiktok",
    "migrationKey": "joinTiktok",
    "order": 6,
    "reward": 10000,
    "url": "https://www.tiktok.com/@gemzfun"
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "2_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite2",
    "id": "invite_2",
    "migrationKey": "inviteFriends2",
    "order": 1,
    "reward": 40000
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "5_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite5",
    "id": "invite_5",
    "migrationKey": "inviteFriends5",
    "order": 3,
    "reward": 150000
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "10_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite10",
    "id": "invite_10",
    "migrationKey": "inviteFriends10",
    "order": 5,
    "reward": 300000
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "100_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite100",
    "id": "invite_100",
    "migrationKey": "inviteFriends100",
    "order": 5,
    "reward": 3000000
  },
  {
    "actionCTA": "visit",
    "analytics": "JOIN_Merge_Pals",
    "category": "video",
    "checkCTA": "check_word",
    "creativeUrl": "https://notgemz.cms.gemz.fun/media/powerups/merge_pals.png",
    "endTime": 1738947600000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/merge_pals.png",
    "id": "join_merge_pals",
    "order": 1,
    "reward": 150000,
    "startTime": 1734562800000,
    "url": "https://t.me/mergepalsbot/game?startapp=Gemz"
  },
  {
    "actionCTA": "visit",
    "analytics": "Join_Streaks",
    "category": "video",
    "checkCTA": "check_word",
    "creativeUrl": "https://notgemz.cms.gemz.fun/media/powerups/join_streaks_small.png",
    "endTime": 1738947600000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/join_streaks_small.png",
    "id": "join_streaks",
    "order": 2,
    "reward": 150000,
    "startTime": 1733958000000,
    "url": "http://t.me/tapps/app?startapp=section_streaks_promo_gemz"
  },
  {
    "actionCTA": "visit",
    "analytics": "join_billionaires_path",
    "category": "video",
    "checkCTA": "check_word",
    "creativeUrl": "https://notgemz.cms.gemz.fun/media/powerups/visit_billionaires_path.png",
    "endTime": 1738947600000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/visit_billionaires_path.png",
    "id": "visit_billionaires_path",
    "order": 2,
    "reward": 150000,
    "startTime": 1733439600000,
    "url": "https://t.me/billionaires_path_bot/billypath?startapp=0-UVwXMxu3PmXQJJ2l"
  },
  {
    "actionCTA": "visit",
    "analytics": "join_fishin_frenzy",
    "category": "video",
    "checkCTA": "check_word",
    "creativeUrl": "https://notgemz.cms.gemz.fun/media/powerups/join_fishin_frenzy.png",
    "endTime": 1738959780000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/join_fishin_frenzy.png",
    "id": "join_fishin_frenzy",
    "order": 3,
    "reward": 150000,
    "startTime": 1731085200000,
    "url": "https://t.me/fishingfrenzy_bot/fishingfrenzyapp?startapp "
  },
  {
    "actionCTA": "buy_meme",
    "analytics": "buy_meme",
    "category": "video",
    "checkCTA": "check_word",
    "icon": "meme",
    "id": "buy_meme",
    "order": 7,
    "reward": 250000
  },
  {
    "actionCTA": "create_meme",
    "analytics": "create_meme",
    "category": "video",
    "checkCTA": "check_word",
    "icon": "meme",
    "id": "create_meme",
    "order": 7,
    "reward": 5000000
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1738105200000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "TG_TG235_2025-01-07",
    "order": 10,
    "reward": 50000,
    "startTime": 1736290800000,
    "url": "https://go.gemz.fun/5U0P6s"
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1738191600000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "TG_TG236_2025-01-22",
    "order": 10,
    "reward": 50000,
    "startTime": 1737586800000,
    "url": "https://go.gemz.fun/DOz58U"
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1738278000000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "TG_TG237_2025-01-23",
    "order": 10,
    "reward": 50000,
    "startTime": 1737673200000,
    "url": "https://go.gemz.fun/FmNojN"
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1738364400000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "TG_TG238_2025-01-24",
    "order": 10,
    "reward": 50000,
    "startTime": 1737759600000,
    "url": "https://go.gemz.fun/yZFtlu"
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1738450800000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "TG_TG239_2025-01-25",
    "order": 10,
    "reward": 50000,
    "startTime": 1737846000000,
    "url": "https://go.gemz.fun/GUPH8R"
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1738537200000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "TG_TG240_2025-01-26",
    "order": 10,
    "reward": 50000,
    "startTime": 1737932400000,
    "url": "https://go.gemz.fun/FNF3rG"
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1738623600000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "TG_TG241_2025-01-27",
    "order": 10,
    "reward": 50000,
    "startTime": 1738018800000,
    "url": "https://go.gemz.fun/76ZfEp"
  },
  {
    "actionCTA": "visit",
    "analytics": "JOIN_Cryptokitties",
    "category": "video",
    "checkCTA": "check_word",
    "creativeUrl": "https://notgemz.cms.gemz.fun/media/powerups/join_cryptokitties.png",
    "endTime": 1738947600000,
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/join_cryptokitties.png",
    "id": "join_cryptokitties",
    "order": 1,
    "promo": true,
    "reward": 150000,
    "startTime": 1734994800000,
    "url": "https://t.me/CKMeowBot/AllTheZen?startapp=source_gemz"
  }
];
export default earnQuests;
